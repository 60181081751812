import React from "react"
import { useLocation } from "@reach/router"

import HeaderBic from "../components/HeaderComponents/HeaderBic"
import DownloadSection from "../components/DownloadAppSection/DownloadSection"
import MissionSection from "../components/Mission Section/MissionSection"
import Footer from "../components/FooterComponents/Footer"
import Media from "../components/SendMoney/Media"
import WhereCanFind from "../components/SwiftCodes/WhereCanFind"

import { MainPageJson, PathJson, SwiftCodesJson } from "../components/location"
import Seo from "../components/SEOComponents/Seo"
import { WhereAndWhenSwift } from "../components/SwiftCodes/WhereAndWhen"
import Download from "../components/HomeComponents/Download"
import { NavigationBar } from "../components/NavigationBar/parent"

const BicSwiftCodes = props => {
  const MainPageContent = MainPageJson()
  const PathContent = PathJson()
  const SwiftCodesContent = SwiftCodesJson()
  const location = useLocation()
  const isEnglish = location.pathname.includes("/en")

  return (
    <div className="maxwidth">
      <Seo title="bic-checker" />
      <NavigationBar />
      <HeaderBic
        props={MainPageContent.Header}
        propsLink={PathContent}
        propsData={SwiftCodesContent}
      />
      <WhereAndWhenSwift propsData={SwiftCodesContent} />
      <Download props={MainPageContent.Download} />
      <Footer props={MainPageContent.Footer} propsLink={PathContent} />
    </div>
  )
}

export default BicSwiftCodes
