import React, { useState, useEffect } from "react"
import { useLocation, navigate } from "@reach/router"
import axios from "axios"
import { Link } from "gatsby"
import { useStateIfMounted } from "use-state-if-mounted"
import { Row, Col, Container, Button } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretRight } from "@fortawesome/free-solid-svg-icons"

import NavigationBar from "./NavigationBar"
import iconNotCorrect from "../../images/icon-swift-not-correct.svg"
import CircleSvg from "../../images/bg-circle-half.svg"
import FavIcon from "../../images/favicon.png"

import {
  AffiliateDot,
  RightCol,
  DetailsHeader,
  CheckContainer,
  IconCorrectImg,
  CheckH1,
  DealDiv,
  DealWrap,
  LogoImg,
  BlueButton,
  TransparentButton,
  SpanInline,
  BicInput,
  BicButton,
  BicUl,
  BicLiCorrect,
  BicLiWrong,
  CheckP,
} from "./HeaderStyle"
import { RightCardComponents } from "./HeaderSwiftCodeDetails"
import { CustomButton } from "../Buttons/parent"

function HeaderBic({ props, propsLink, propsData, propsSwiftCode }) {
  const location = useLocation()
  const isEnglish = location.pathname.includes("/en")
  const json = propsData.Details.Header
  const leftColJson = propsData.Bic

  const [swiftCode, setSwiftCode] = useState({
    code: undefined,
  })

  const [wrongCode, setWrongCode] = useState({
    bank: "",
    country: "",
    country_code: "",
    city: "",
    branch: "",
  })

  const [showWrong, setShowWrong] = useState(false)
  const [allFail, setAllFail] = useState(false)
  const [countryFound, setCountryFound] = useState(false)

  async function fetchData() {
    await axios
      .get(
        `https://strapi.transfez.com/swift-codes?swift_code_eq=${swiftCode.code}`
      )
      .then(res => {
        if (res.data.length === 0) {
          if (swiftCode.code.length > 7 && swiftCode.code.length < 12) {
            const CountryCheck = swiftCode.code.substring(4, 6)
            axios
              .get(
                `https://strapi.transfez.com/swift-codes?country_code_eq=${CountryCheck}`
              )
              .then(res => {
                setWrongCode({
                  country: res.data[0].country,
                  bank: swiftCode.code.substring(0, 4),
                  country_code: swiftCode.code.substring(4, 6),
                  city: swiftCode.code.substring(6, 8),
                  branch: swiftCode.code.substring(8, 11),
                })
                setCountryFound(true)
              })
              .catch(err => {
                setWrongCode({
                  country: CountryCheck,
                  bank: swiftCode.code.substring(0, 4),
                  country_code: swiftCode.code.substring(4, 6),
                  city: swiftCode.code.substring(6, 8),
                  branch: swiftCode.code.substring(8, 11),
                })
                setCountryFound(false)
              })
            setAllFail(false)
          } else {
            setAllFail(true)
          }
          setShowWrong(true)
        } else {
          const newLink = res.data[0].swift_code.toLowerCase()
          navigate(
            isEnglish ? `/en/swift-codes/${newLink}` : `/swift-codes/${newLink}`
          )
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  function onSubmit(e) {
    e.preventDefault()
    fetchData()
  }

  function onChange(e) {
    setSwiftCode({ code: e.target.value })
  }

  useEffect(() => {
    setSwiftCode({ code: localStorage.getItem("WrongCode") })
    fetchData()
  }, [])

  // useEffect(() => {
  //   fetchData()
  // }, [propsSwiftCode])

  return (
    <>
      <DetailsHeader>
        <CheckContainer>
          <Row>
            <Col md={6}>
              <div className="d-flex align-items-center" style={{ gap: 15 }}>
                <IconCorrectImg src={iconNotCorrect} alt="Not Correct" />
                <CheckH1>{leftColJson.Title1}</CheckH1>
              </div>
              <CheckP>
                {leftColJson.Desc1}
                <br />
                {leftColJson.Desc2}
              </CheckP>
              <CheckP>{showWrong ? leftColJson.Desc3 : null}</CheckP>
              <form onSubmit={onSubmit}>
                <BicInput
                  type="text"
                  name="swiftcode"
                  className="form-control"
                  value={swiftCode.code}
                  onChange={onChange}
                  placeholder={leftColJson.Placeholder}
                />
                {allFail ? (
                  <>
                    <BicUl>
                      <BicLiWrong>{leftColJson.Empty1}</BicLiWrong>
                      <BicLiWrong>{leftColJson.Empty2}</BicLiWrong>
                      <BicLiWrong>{leftColJson.Empty3}</BicLiWrong>
                      <BicLiWrong>{leftColJson.Empty4}</BicLiWrong>
                    </BicUl>
                  </>
                ) : (
                  <>
                    {showWrong ? (
                      <>
                        <BicUl>
                          <BicLiWrong>
                            {leftColJson.Desc4}
                            {wrongCode.bank}
                            {leftColJson.Wrong}
                          </BicLiWrong>
                          {countryFound ? (
                            <BicLiCorrect>
                              {leftColJson.Desc5}
                              {wrongCode.country_code}
                              {leftColJson.Correct}
                              {wrongCode.country}
                            </BicLiCorrect>
                          ) : (
                            <BicLiWrong>
                              {leftColJson.Desc5}
                              {wrongCode.country}
                              {leftColJson.Wrong}
                            </BicLiWrong>
                          )}
                          <BicLiWrong>
                            {leftColJson.Desc6}
                            {wrongCode.city}
                            {leftColJson.Wrong}
                          </BicLiWrong>
                          <BicLiWrong>
                            {leftColJson.Desc7}
                            {wrongCode.branch}
                            {leftColJson.Wrong}
                          </BicLiWrong>
                        </BicUl>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
                <CustomButton padding="10px 30px" type="submit">
                  {leftColJson.Button1}
                </CustomButton>
              </form>
            </Col>
            <RightCol className="offset-lg-1">
              <RightCardComponents json={json} propsLink={propsLink} />
            </RightCol>
          </Row>
        </CheckContainer>
      </DetailsHeader>
    </>
  )
}

export default HeaderBic
